import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../admin/responsibility/searchModule';
import advanceSearch from './advanceSearch';
import appStrings from '@/app/utility/string.utility';
import applyReceiptDetails from './applyReceiptDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'applyReceipt',
  components: {
    advanceSearch,
    applyReceiptDetails,
    ModulesList
  },
  watch: {
    currentPage: function() {
      this.getApplyReceiptListDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getApplyReceiptListDetails();
    }
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      searchParams: null,
      showModal: false,
      valueDate: null,
      payload: {},
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      parent_value_set_id: null,
      showAdvSearchModal: false,
      selectedModule: {
        name: null,
        id: null
      },
      vsetCode: null,
      showValueSetModal: false,
      tab: null,
      legalEntity: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      receiptCn: null,
      showApplyReceiptDetailsForm: false,
      applyReceiptRowData: null,
      applyReceiptData: [],
      applyReceiptFields: [
        {
          key: 'apply'
        },
        {
          key: 'receipt_cn',
          label: 'Receipt/CN'
        },
        {
          key: 'receipt_number'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'project'
        },
        {
          key: 'unit_name',
          label: 'Unit'
        },
        {
          key: 'sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'apply_type'
        },
        {
          key: 'instrument_amount',
          label: 'Amount'
        },
        {
          key: 'applied_amt',
          label: 'Applied'
        },
        {
          key: 'unapplied_amt',
          label: 'UnApplied'
        },
        {
          key: 'value_date'
        },
        {
          key: 'clearing_date',
          label: 'Clear Date'
        }
      ],
      trxType: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    selectedModule: {
      id: { required }
    },
    trxType: {
      value: { required }
    },
    legalEntity: {
      value:{ required }
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && !this.showApplyReceiptDetailsForm) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'applyReceipt/getArApplyReceiptTransaction',
            'apply-reciept',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getApplyReceiptListDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          }
        };
        if (!this.searchParams) {
          this.payload = {
            pagination: {
              _page: this.currentPage - 1,
              _limit: this.perPage
            },
            searchParams: {
              with_unit: true,
              module_id: this.selectedModule.id,
              document_num: this.receiptCn,
              le_id: this.legalEntity.value,
              prj_id: this.project.value,
              trx_type_dtl_id: this.trxType.value
            }
          };
        } else {
          this.payload.searchParams = this.searchParams;
        }
        this.loader = true;
        this.$store
          .dispatch('applyReceipt/getArApplyReceiptTransaction', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.applyReceiptData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;
      this.trxType = {
        value: null,
        text: null
      };
    },
    getMenuList() {},
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if ( this.vsetCode === appStrings.VALUESETTYPE.AR_TRX_TYPE_APPLY_UNAPPLY_VSET ) {
        if(this.selectedModule.id){
          this.parent_value_set_id = this.selectedModule.id;
        } else{
          alert("Please Select Module First")
          return
        }
        
      } 
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.AR_TRX_TYPE_APPLY_UNAPPLY_VSET
      ) {
        this.trxType = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearFilters() {
      this.selectedModule = {
        name: null,
        id: null
      };
      this.legalEntity = {
        value: null,
        text: null
      };
      this.project = {
        value: null,
        text: null
      };
      this.trxType = {
        value: null,
        text: null
      };
      this.receiptCn = null;
      this.applyReceiptData = [];
      this.actionName = null;
      this.menuTitle = null;
    },
    advanceSearch(params) {
      this.selectedModule.id = params.searchParams.module_id;
      this.trxType.value = params.searchParams.trx_type_dtl_id;
      this.legalEntity.value = params.searchParams.le_id;
      this.searchParams = params.searchParams;
      this.showAdvSearchModal = false;
      this.getApplyReceiptListDetails();
    },
    openApplyReceipt(flag, item) {
      this.showApplyReceiptDetailsForm = flag;
      this.applyReceiptRowData = item;
      // this.getDefaultDate(this.applyReceiptRowData.module_id);
    },
    rowSelected(item) {
      this.showApplyReceiptDetailsForm = true;
      this.applyReceiptRowData = item;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    mainSearch() {
      this.searchParams = null;
      this.getApplyReceiptListDetails();
    },
    clearVsetValues(vsetCode){
      if (vsetCode === this.trxType.value) {
        this.trxType = {
          value: null,
          text: null
        }
      }else if(vsetCode === this.legalEntity.value){
        this.legalEntity = {
          value: null,
          text: null
        }
      }else if(vsetCode === this.project.value){
        this.project = {
          value: null,
          text: null
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('applyReceipt/saveAdvSearchFilters', null);
    // this.$store.state.applyReceipt.applyReceipt.adv_search = null;
  }
};
