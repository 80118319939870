import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { required } from 'vuelidate/lib/validators';
import party from '@/app/components/generalLedger/party';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
export default {
  name: 'AdvanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    DatePicker,
    ModulesList,
    party
  },
  watch: {
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    }
  },
  data() {
    return {
      vsetCode: null,
      setTimeout: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      isDependent: false,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      loader: false,
      clearDate: null,
      site: null,
      applyType: null,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        // leagalEntity: [
        //   {
        //     value: null,
        //     text: '--select--'
        //   }
        // ],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ]
      },
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      customer: {
        name: null,
        id: null
      },
      customerSite: {
        name: null,
        id: null
      },
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedInstrumentStatus: {
        value: null,
        text: null
      },
      selectedAccountNum: {
        value: null,
        text: null
      },
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      instrumentStartAmount: null,
      instrumentEndAmount: null,
      isAdvanceSearchEnable: false,
      search: '',
      data: [],
      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      creation_start_date: null,
      creation_end_date: null,
      gl_start_date: null,
      gl_end_date: null,
      value_start_date: null,
      value_end_date: null,
      valueDate: null,
      glDate: null,
      creationDate: null,
      showHideModule: false,
      selectedModule: {
        name: null,
        id: null
      },
      trxType: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    selectedModule: {
      name: { required }
    },
    trxType: {
      value: { required }
    },
    legalEntity: {
      value: { required }
    }
  },
  mounted() {
    const advFormData = this.$store.state.applyReceipt.applyReceipt.adv_search;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      this.getInstrumentStatus();
    }
  },
  methods: {
    // selectedTowerFun(towerVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
    //   this.lovId = towerVal.value;
    //   this.selectedTower = towerVal;
    //   this.getOtherLovByProjectId();
    // },
    // selectedFloorFun(floorVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
    //   this.selectedFloor = floorVal;
    //   this.lovId = floorVal.value;
    //   this.getOtherLovByProjectId();
    // },
    // selectedUnitFun(unitVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
    //   this.selectedUnit = unitVal;
    //   this.lovId = unitVal.value;
    //   this.getOtherLovByProjectId();
    // },
    // selectedSubUnitFun(subUnitVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
    //   this.selectedSubUnit = subUnitVal;
    //   this.lovId = subUnitVal.value;
    //   this.getOtherLovByProjectId();
    // },
    getInstrumentStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.instrumentStatusList = getValueAndText;
          }
        });
    },
    // getProjectList(leId) {
    //   const payload = {
    //     typeOfProject: 'FMSPRJ',
    //     le_id: leId
    //   };
    //   this.$store.dispatch('fms/getProjectList', payload).then(response => {
    //     if (response.status === 200) {
    //       const results = response.data.data;
    //       const getValueAndText = results.map(type => {
    //         return {
    //           value: type.proj_id,
    //           text: type.proj_name
    //         };
    //       });
    //       this.advanceSearchForm.projectList = getValueAndText;
    //     }
    //   });
    // },
    // getOtherLovByProjectId() {
    //   this.loader = true;
    //   const payload = {
    //     lovFieldName: this.lovFieldName,
    //     lovId: this.lovId
    //   };
    //   this.$store
    //     .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response && response.data && response.data.data) {
    //         const results = response.data.data;
    //         if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
    //           this.getSectorList(results.sectors);
    //           this.getPocketList(results.pockets);
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
    //           this.getTowerList(results.towers);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //         }
    //       }
    //     })
    //     .catch(error => {
    //       this.loader = false;
    //       alert(error.message);
    //     });
    // },
    // getSectorList(sectorList) {
    //   if (sectorList && sectorList.length) {
    //     const valueAndTextArr = sectorList.map(type => {
    //       return {
    //         value: type.sector_id,
    //         text: type.sector_name
    //       };
    //     });
    //     this.advanceSearchForm.sectorList = valueAndTextArr;
    //     this.selectedSector =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedSector = this.keyValue;
    //     this.advanceSearchForm.sectorList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getPocketList(pocketList) {
    //   if (pocketList && pocketList.length) {
    //     const valueAndTextArr = pocketList.map(type => {
    //       return {
    //         value: type.pocket_id,
    //         text: type.pocket_name
    //       };
    //     });
    //     this.advanceSearchForm.pocketList = valueAndTextArr;
    //     this.selectedPocket =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedPocket = this.keyValue;
    //     this.advanceSearchForm.pocketList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getTowerList(towerList) {
    //   if (towerList && towerList.length) {
    //     const valueAndTextArr = towerList.map(type => {
    //       return {
    //         value: type.tower_id,
    //         text: type.tower_name
    //       };
    //     });
    //     this.advanceSearchForm.towerList = valueAndTextArr;
    //     this.selectedTower =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedTower = this.keyValue;
    //     this.advanceSearchForm.towerList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getFloorList(floorList) {
    //   if (floorList && floorList.length) {
    //     const valueAndTextArr = floorList.map(type => {
    //       return {
    //         value: type.floor_id,
    //         text: type.floor_name
    //       };
    //     });
    //     this.advanceSearchForm.floorList = valueAndTextArr;
    //     this.selectedFloor =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedFloor = this.keyValue;
    //     this.advanceSearchForm.floorList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getUnitList(unitList) {
    //   if (unitList && unitList.length) {
    //     const valueAndTextArr = unitList.map(type => {
    //       return {
    //         value: type.unit_id,
    //         text: type.unit_name
    //       };
    //     });
    //     this.advanceSearchForm.unitList = valueAndTextArr;
    //     this.selectedUnit =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedUnit = this.keyValue;
    //     this.advanceSearchForm.unitList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getSubUnitList(subUnitList) {
    //   if (subUnitList && subUnitList.length) {
    //     const valueAndTextArr = subUnitList.map(type => {
    //       return {
    //         value: type.sub_unit_id,
    //         text: type.sun_unit_name
    //       };
    //     });
    //     this.advanceSearchForm.subUnitList = valueAndTextArr;
    //     this.selectedSubUnit =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.selectedSubUnit = this.keyValue;
    //     this.advanceSearchForm.unitList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    onSelect(event) {
      this.selectedReceiptAgainst = event;
    },
    searchApplyReceiptParams() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          searchParams: {
            module_id: this.selectedModule.id,
            with_unit:
              this.advanceSearchForm.receiptAgainst.value === 'SUBUNIT',
            // le_id: this.advanceSearchForm.leagalEntity.value,
            receipt_mode_vset: this.advanceSearchForm.paymentMode.value,
            document_num: this.receiptNum,
            customer_id: this.customer.id,
            customer_site_id: this.customerSite.id,
            bank_vset: this.advanceSearchForm.drawnOn.value,
            prj_id: this.selectedProject.value,
            tower_id: this.selectedTower.value,
            floor_id: this.selectedFloor.value,
            unit_id: this.selectedUnit.value,
            sub_unit_id: this.selectedSubUnit.value,
            instrument_status_vset: this.selectedInstrumentStatus.value,
            instrument_start_amount: this.instrumentStartAmount,
            instrument_end_amount: this.instrumentEndAmount,
            value_start_date: this.valueDate
              ? format(this.valueDate[0], appStrings.DATEFNSFORMAT)
              : null,
            value_end_date: this.valueDate
              ? format(this.valueDate[1], appStrings.DATEFNSFORMAT)
              : null,
            gl_start_date: this.glDate
              ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
              : null,
            gl_end_date: this.glDate
              ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
              : null,
            creation_start_date: this.creationDate
              ? format(this.creationDate[0], appStrings.DATEFNSFORMAT)
              : null,
            creation_end_date: this.creationDate
              ? format(this.creationDate[1], appStrings.DATEFNSFORMAT)
              : null,
            trx_type_dtl_id: this.trxType.value,
            le_id: this.legalEntity.value
          }
        };
        this.$emit('advanceSearch', payload);
        this.$store.dispatch('applyReceipt/saveAdvSearchFilters', this);
        // this.$store.state.applyReceipt.applyReceipt.adv_search = this;
      }
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.customer.id
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.customerSite.name = item.site_name;
      this.customerSite.id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    clearApplyReceiptParams() {
      this.selectedPaymentMode = this.keyValue;
      this.selectedDrawnOn = this.keyValue;
      this.selectedProject = this.keyValue;
      this.selectedSector = this.keyValue;
      this.selectedPocket = this.keyValue;
      this.selectedTower = this.keyValue;
      this.selectedFloor = this.keyValue;
      this.selectedUnit = this.keyValue;
      this.selectedSubUnit = this.keyValue;
      this.selectedInstrumentStatus = this.keyValue;
      this.gl_start_date = null;
      this.gl_end_date = null;
      this.advanceSearchForm.receiptAgainst = [
        {
          value: null,
          text: null
        }
      ];
      // this.advanceSearchForm.leagalEntity = [
      //   {
      //     value: null,
      //     text: '--select--'
      //   }
      // ];
      this.advanceSearchForm.paymentMode = [
        {
          value: null,
          text: null
        }
      ];
      this.advanceSearchForm.drawnOn = [
        {
          value: null,
          text: null
        }
      ];
      this.instrumentStartAmount = null;
      this.instrumentEndAmount = null;
      this.receiptNum = '';
      this.creation_start_date = null;
      this.creation_end_date = null;
      this.value_start_date = null;
      this.value_end_date = null;
      this.valueDate = null;
      this.glDate = null;
      this.creationDate = null;
      this.site = null;
      this.applyType = null;
      this.clearDate = null;
      this.customer = {
        name: null,
        id: null
      };
      this.customerSite = {
        name: null,
        id: null
      };
      this.selectedModule = {
        name: null,
        id: null
      };
      this.trxType = {
        text: null,
        value: null
      };
      this.legalEntity ={
        text: null,
        value: null
      }
      this.$store.dispatch('applyReceipt/saveAdvSearchFilters', null);
      // this.$store.state.applyReceipt.applyReceipt.adv_search = null;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.parent_value_set_id = this.selectedProject.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.parent_value_set_id = this.selectedTower.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.parent_value_set_id = this.selectedFloor.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.selectedUnit.value;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.parent_value_set_id = this.selectedModule.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.AR_TRX_TYPE_APPLY_UNAPPLY_VSET) {
        if( this.selectedModule.id){
          this.parent_value_set_id = this.selectedModule.id;
        } else {
          alert("Please Select Module First");
          return
        }
      } else {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'FMS_SEARCH_BY') {
        this.advanceSearchForm.receiptAgainst.value = item.value_code;
        this.advanceSearchForm.receiptAgainst.text = item.value_meaning;
      } 
      // else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
      //   this.advanceSearchForm.leagalEntity.value = item.org_id;
      //   this.advanceSearchForm.leagalEntity.text = item.org_name;
      // } 
      else if (this.vsetCode === 'PAYMENT_MODE') {
        this.advanceSearchForm.paymentMode.value = item.value_code;
        this.advanceSearchForm.paymentMode.text = item.value_meaning;
      } else if (this.vsetCode === 'BANK_NAME_VSET') {
        this.advanceSearchForm.drawnOn.value = item.value_code;
        this.advanceSearchForm.drawnOn.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.selectedTower.text = item.value_code;
        this.selectedTower.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.selectedFloor.text = item.value_code;
        this.selectedFloor.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.selectedUnit.text = item.value_code;
        this.selectedUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.selectedSubUnit.text = item.value_code;
        this.selectedSubUnit.value = item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.AR_TRX_TYPE_APPLY_UNAPPLY_VSET
      ) {
        this.trxType.text = item.value_code;
        this.trxType.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
          this.legalEntity.value = item.org_id;
          this.legalEntity.text = item.org_name;
        } 
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
      if (this.selectedModule.name === 'Lease Management') {
        this.advanceSearchForm.receiptAgainst = {
          text: 'With SubUnit',
          value: 'SUBUNIT'
        };
      } else {
        this.advanceSearchForm.receiptAgainst = {
          value: null,
          text: null
        };
      }
      this.trxType = {
        value: null,
        text: null
      };
    },
    setSearchItems(item) {
      this.selectedModule = item.selectedModule;
      this.advanceSearchForm = item.advanceSearchForm;
      this.receiptNum = item.receiptNum;
      this.customer = item.customer;
      this.customerSite = item.customerSite;
      this.selectedProject = item.selectedProject;
      this.selectedTower = item.selectedTower;
      this.selectedFloor = item.selectedFloor;
      this.selectedUnit = item.selectedUnit;
      this.selectedSubUnit = item.selectedSubUnit;
      this.selectedInstrumentStatus = item.selectedInstrumentStatus;
      this.instrumentStartAmount = item.instrumentStartAmount;
      this.instrumentEndAmount = item.instrumentEndAmount;
      this.valueDate = item.valueDate;
      this.clearDate = item.clearDate;
      this.glDate = item.glDate;
      this.creationDate = item.creationDate;
      this.selectedFloor = item.selectedFloor;
      this.selectedUnit = item.selectedUnit;
      this.selectedSubUnit = item.selectedSubUnit;
      this.trxType = item.trxType;
      this.legalEntity = item.legalEntity;
    },
    chooseValueset(module) {
      if (module === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
  }
};
